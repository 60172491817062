html,
body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
li {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

svg {
  display: block;
}

iframe {
  border: 0;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: AnzeigenGroteskRegular;
  src: url('/fonts/AnzeigenGrotesk-Regular.woff2');
}

@font-face {
  font-family: QanelasSoftBlack;
  src: url('/fonts/QanelasSoft-Black.woff2');
}

@font-face {
  font-family: QanelasSoftRegular;
  src: url('/fonts/QanelasSoft-Regular.woff2');
}

@font-face {
  font-family: QanelasSoftSemiBold;
  src: url('/fonts/QanelasSoft-SemiBold.woff2');
}

#gdpr-toggle {
  display: none !important;
}

#ot-sdk-btn-floating {
  display: none !important;
}

#onetrust-banner-sdk.otChoicesBnr {
  top: 0% !important;
  font-family: QanelasSoftRegular;
}

@media (min-width: 768px) {
  #onetrust-banner-sdk.otChoicesBnr {
    top: 15% !important;
  }
}
